.horizontal {
    display: flex;
    align-items: baseline;
}

.number-input {
    width: 4.5rem !important;
}

.multiplication {
    margin: 0 0.5rem;
}

.label {
    margin-left: 0.3rem;
}

.number-input::-webkit-inner-spin-button, .number-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}