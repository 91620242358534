.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

#hamburgertime {
  margin-left: -16px;
}

#logo {
  padding-left: 5px;
}
