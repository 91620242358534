button[type="submit"] {
  background-color: #2196f3;
}

.loading-spinner.spin {
  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.no-border-radius {
  border-radius: 0 !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.black-bg {
  background-color: black;
}

.floating-item {
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 5px;
}

.white-txt {
  color: white;
}
