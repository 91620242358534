.confirm-box {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  right: 0;
  bottom: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
}

.confirm-box .confirm-box-contents {
  background: #222;
  padding: 20px;
  color: white;
  min-width: 400px;
  min-height: 200px;
  max-width: 400px;
  border-radius: 10px;
  box-shadow: 0 0 10px black;
}

.confirm-box .confirm-box-contents .title {
  font-size: 18px;
  height: 120px;
}

.confirm-box .buttons {
  justify-content: flex-end;
  display: flex;
}
