.active-cyan-4 input[type="text"]:focus:not([readonly]) {
  border: 1px solid #26a69a;
  box-shadow: 0 0 0 1px #26a69a;
}

.ui-grid-cell-contents-auto {
  /* display: grid;
    grid-auto-columns: max-content; */
}

.editable {
  background-color: #c3c3c3;
}
