.container p {
  margin: 0;
}

.table {
  border-collapse: collapse;
  /* width: 46.5%; */
  text-align: left;
  margin-left: 10px;
  margin-right: 10px;
  width: 750px;
  font-size: 13px;
}

.right_table {
  border: 1px solid black;
  text-align: right;
  width: 260px;
  float: right;
  margin-right: 10px;
  font-size: 13px;
  font-weight: bold;
}

.right_table td {
  border: 1px solid black;
}

.table td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 2px;
  font-size: 13px;
}
.table th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 2px;
  font-size: 13px;
}
.table thead {
  text-align: left;
  font-weight: bold;
}

.table tr:nth-child(even) {
  background-color: #dddddd;
}

.headerInfo {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 775px;
  margin: 1px;
  font-size: 13px;
}

.reportHeader {
  border: 1px solid black;
  align-self: flex-end;
  width: 775px;
  text-align: center;
  margin-bottom: 2px;
  font-size: 13px;
}

.vendorHeader {
  /* grid-column: 1/2; */
  border: 0.1px solid black;
  padding-left: 2px;
  margin: 1px;
  font-size: 13px;
}

.warehouseHeader {
  border: 0.1px solid black;
  padding-left: 2px;
  margin: 1px;
  font-size: 13px;
}

.shipToHeader {
  /* grid-column: 2/3; */
  border: 0.1px solid black;
  padding-left: 2px;
  margin: 1px;
  font-size: 13px;
}

.carrierHeader {
  /* grid-column: 3/4; */
  border: 0.1px solid black;
  padding-left: 2px;
  margin: 1px;
  font-size: 13px;
}

.divToPrint {
  font-family: arial, sans-serif;
  font-size: 13px;
  padding: 10px;
  margin: 20px;
}

.line {
  border-top: 1px solid #000;
  margin-left: 0px;
  width: 775px;
}
.w150 td {
  width: 150px;
}
.footer {
  height: 50px;
}
