body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282c34;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ag-root-wrapper {
  width: 102%;
  cursor: default;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: none !important;
  border: 3px solid #eee;
  padding: 10px;
  /* box-shadow: 4px 10px #3333334D; */
}

.ag-theme-balham .ag-icon-menu:before {
  /* content: ""; */
  display: none;
}

.ag-header-cell-label,
.ag-header-cell-label .ag-header-cell-text {
  justify-content: center;
  text-overflow: clip;
  overflow: visible;
  white-space: normal;
}

.ag-header-cell-label .ag-header-cell-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: black;
}

.cardStyle {
  width: auto;
  padding: 10px;
}

.cardBodyStyle {
  margin-bottom: -15px;
  margin-top: -25px;
}

.modalSize {
  max-width: fit-content !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Removes blue border on selected tables */
.ag-cell-focus,
.ag-cell-no-focus {
  border: none !important;
}

.no-border.ag-cell:focus {
  border: none !important;
  outline: none;
}

/* .ag-icon.ag-icon-desc,
.ag-icon.ag-icon-asc,
.ag-icon.ag-icon-filter {
  display: block;
  visibility: hidden !important;
} */
.ag-theme-balham .ag-paging-panel {
  border-top: 1px solid #bdc3c7;
  color: rgba(0, 0, 0, 1.54);
  height: 32px;
  padding: 0 12px;
}

.ag-theme-balham .ag-paging-page-summary-panel .ag-icon {
  width: 16px;
  height: 16px;
  font-weight: bolder;
  color: #2196f3;
}

.white-background {
  background-color: white;
}

.ag-center-cols-viewport {
  overflow: hidden;
}

.ag-cell {
  user-select: text !important;
}
/*.ag-gride-coustomize{*/
/*   position: relative;*/
/*}*/
.selection-font {
  font-size: 18px;
  color: grey;
}
.selection-input {
  width: 100px;
  font-size: 14px;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid lightgrey;
}
.border-red {
  border: 1px solid red !important;
}

.error-color {
  color: red;
  font-size: 14px;
}
